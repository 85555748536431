<template>
<div class="container">

    <Header :title="$t('tasks.tasks')" />

    <CreateTask 
        :user="auth"
        :fields="edit"
        :members="members"
        @save="saveTask($event)"
    />

    <div v-if="tasks.length>0">

        <!-- timeline -->
        <TaskChart :tasks="tasks" />

        <!-- search bar -->
        <SearchBar :text="$t('tasks.find')" v-model="search" />

        <b-button 
            class="mx-1 my-2"
            size="sm"
            :variant="sort=='ends' ? 'primary' : 'outline-primary'"
            @click="sortTasks(sort='ends')"
            v-text="$t('tasks.sort_ends')"            
        />

        <b-button 
            class="mx-1 my-2"
            size="sm"
            :variant="sort=='starts' ? 'primary' : 'outline-primary'"
            @click="sortTasks(sort='starts')"
            v-text="$t('tasks.sort_starts')"
        />

        <!-- task list -->
        <Task 
            v-for="(task, index) in filterTasks" 
            :key="index" 
            :task="task" 
            @del="delItem('tasks',task.id,index)"
            @edit="editIt(index)"
        />

    </div>

    <!-- no access -->
    <Request :contents="tasks" :text="$t('tasks.none')" />

    <PlusButton @click="clearEdit(); $bvModal.show('new-task')" />

</div>
</template>

<script>
import SearchBar from "@/views/BaseComponents/SearchBar.vue";
import CreateTask from "@/views/TaskComponents/CreateTask.vue";
import TaskChart from "@/views/TaskComponents/TaskChart.vue";
import Task from "@/views/TaskComponents/Task.vue";
import Vue from 'vue';

export default {
    name: "Tasks",
    components: { SearchBar, CreateTask, TaskChart, Task },
    async created() { 
        this.notify = ['Tasks',0];
        await this.getTasks(); 

        // event listener
        window.Echo.private('tasks.'+ this.auth.project.id)
            .listen('PostEvent', (incoming) => { 
                if(incoming.action=='add') this.pushTask(incoming.post);
                if(incoming.action=='del') this.removeTask(incoming.post);
                if(incoming.action=='update') this.updateTask(incoming.post);
        });

    },
    data() {return{
        search: '',
        tasks: [],
        edit: {},
        members: [],
        sort: 'ends'
    }},
    computed: {
        //editTask() { if(edit!=undefined) return this.edit; else return undefined; },
        filterTasks() {
            return this.tasks.filter(t => {
                return t.title.toLowerCase().includes(this.search.toLowerCase()) ||
                        (t.description && t.description.toLowerCase().includes(this.search.toLowerCase()));

            });
         },


    },
    methods: {

         async pushTask(post) {
            if(this.auth.id==post.user_id) return false;
            let newpost = await this.axon('get','tasks/'+post.id);
            this.tasks.unshift(newpost);
            this.sortTasks(this.sort);
            this.$store.commit("alert",this.$t('tasks.wrote',{name: newpost.user.name}));
            this.notify = ['Tasks',0];
        },

        removeTask(post) {
            let ix = this.index('tasks',post.id);
            if(ix>-1) this.tasks.splice(ix,1);
        },

        async updateTask(post) {
            let ix = this.index('tasks',post.id);
            let newpost = await this.axon('get','tasks/'+post.id);
            if(ix>-1) Vue.set(this.tasks,ix,newpost);
        },


        async getTasks() { 
            let data = await this.axon('get','projects/'+this.auth.project.id); 
            if(data && data.tasks) {
                this.tasks = data.tasks;
                this.members = data.users;
                this.sortTasks(this.sort);
            }
        },
        async saveTask(val) {
            if(!this.auth.project.id) return false;
            if(!val.user_id) val.user_id = this.auth.id;
            val.project_id = this.auth.project.id;
            if(val.id) await this.changeItem('tasks',val); else await this.createItem('task',val);
            this.clearEdit();
            await this.getTasks();
            this.sortTasks(this.sort);
        },
        editIt(index) {
            this.edit = JSON.parse(JSON.stringify(this.tasks[index]));
            this.$bvModal.show('new-task');
        },
        clearEdit() {
            this.edit = { 
                title: '', 
                description: '', 
                starts: this.moment().format('Y-MM-DD'), 
                ends: this.moment().format('Y-MM-DD'), 
                done: false, user_id: this.auth.id }
        },
        sortTasks(sortBy) {
            this.tasks = this.tasks.slice().sort(function(a, b){ return (a[sortBy] > b[sortBy]) ? 1 : -1; });
        }
    }
    
}
</script>