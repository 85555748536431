<template>
<div>
    <!-- modal -->
    <b-modal 
        id="new-task"  
        :title="$t('tasks.task')" 
        headerBgVariant="primary"
        headerTextVariant="white"
        class="pl-0"
        centered
        :okTitle="$t('button.save')"
        :cancelTitle="$t('button.cancel')"
        @ok="$emit('save',task)">
        <!-- user info -->
        <b-form-select 
            v-if="user.project.role=='admin'" 
            v-model="task.user_id" 
            :options="membersList" 
            class="mb-3"
        />
        <UserInfo v-else :subtext="$t('tasks.task')" :user="user" />

        <!-- title -->
        <b-form-input 
            v-model="task.title" 
            :placeholder="$t('holder.title')" 
            class="text-primary text-big text-center mb-3 break-lines"
            maxlength="50"
        />

        <!-- add desc -->
        <b-form-textarea
            v-model="task.description"
            :placeholder="$t('holder.description')"
            rows="2"
            class="mb-2"
        />

        <!-- dates -->
        <b-row>
            <b-col><b-form-input type="date" :placeholder="$t('tasks.starts')" :local="$i18n.locale" v-model="task.starts" /></b-col>
            <b-col><b-form-input type="date" :placeholder="$t('tasks.ends')" :local="$i18n.locale" v-model="task.ends" /></b-col>
        </b-row>

        <!-- done -->
        <div class="my-3 text-center">
                <b-form-checkbox v-model="task.done" name="check-button" switch>
                 <span :class="task.done ? 'text-primary' : 'text-muted'">{{ $t('tasks.done') }}</span>
                </b-form-checkbox>
        </div>


    </b-modal>
</div>
</template>

<script>
import UserInfo from "@/views/PostComponents/UserInfo.vue";

export default {
    name: "CreateTask",
    components: { UserInfo },
    props: ['members','user','fields'],
    data() { return{
        task: this.fields,
    }},  
    computed: {
        membersList() {
            let members = [];
            for(let m in this.members) {
                members.push({value:this.members[m].id,text:this.members[m].name});
            }
            return members;
        },
    },
    watch: { fields: function(val) { this.task = val; }}
}
</script>