<template>
    <div class="mx-0 px-0 w-100">
        <apexchart type="rangeBar" width="100%" height="350" :options="chartOptions" :series="chartSeries"></apexchart>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import moment from 'moment';

export default {
    props: ['tasks'],
    components: { apexchart: VueApexCharts },
    computed: {
        chartSeries() {
            let res = [];
            for(let t in this.tasks) {
                res.push({ 
                    x: [ this.tasks[t].title, this.tasks[t].user.name ],
                    y: [
                        new Date(this.tasks[t].starts).getTime(),
                        new Date(this.tasks[t].ends).getTime()
                    ]
                });
            }
            return [{data: res}];
        }
    },

    data() { return{
          chartOptions: {
            plotOptions: { bar: { horizontal: true, distributed: true}},
            dataLabels: {
              enabled: true,
              formatter: function(val, opts) {
                var label = opts.w.globals.labels[opts.dataPointIndex][0]
                var a = moment(val[0])
                var b = moment(val[1])
                var diff = b.diff(a, 'days')
                return label + ' [' + diff + ']';
              },
              style: { colors: ['#f3f4f5', '#fff'] }
            },
            xaxis: { type: 'datetime' },
            yaxis: { 
                show: true, 
                labels: {
                    formatter: function(val) { return val[1]; },
                    align: 'left',
                    maxWidth: 90,
                    offsetX: 0,
                    offsetY: 0,
                    style: { fontWeight: 600 }
                    }
            },
            grid: { 
                row: { colors: ['#f3f4f5', '#fff'], opacity: 1 },
                padding: { left: 0, right: 0 }
            }
          },

    }}
}
</script>