<template>
    <div class="row justify-content-center text-left bg-white border pt-3 mb-2">

        <div class="col-lg-4 text-small row m-0 p-0">

            <!-- user info -->
            <div class="col-lg-12 col-sm-6 col-12">
                <UserInfo :subtext="$t('tasks.task')" :user="task.user" />
            </div>

            <div class="col-lg-12 col-sm-6 col-12 text-lg-left text-sm-right">
                <div class="controls mb-3 ml-2">

                    <!-- done? -->
                    <b-button 
                        :variant="task.done ? 'success' : 'outline-secondary'" 
                        size="sm" 
                        class="mr-2" 
                        disabled>
                        <b-icon :icon="task.done ? 'check' : 'calendar'" />
                    </b-button>
                    
                    <!-- edit -->
                    <b-button  v-if="auth.project.role=='admin' || task.user.id==auth.id"
                        variant="primary" 
                        size="sm" 
                        class="mr-2"
                        @click="$emit('edit',task.id)">
                        <b-icon icon="pencil-square" />
                    </b-button>
                    
                    <!-- delete -->
                    <b-button v-if="auth.project.role=='admin' || task.user.id==auth.id"
                        variant="danger" 
                        size="sm" 
                        class="mr-2"
                        @click="$emit('del',task.id)">
                        <b-icon icon="x" />
                    </b-button>

                </div>
            </div>
        </div>

        <!-- task data -->
        <div class="col-lg-8 pb-3">         
            <hr class="d-lg-none d-sm-block mt-0 mb-2" />
            <h5 :class="[ task.done ? 'text-success' : 'text-primary']">
                {{ task.title }}
            </h5>
            <p class="text-secondary text-small my-0">{{ task.description }}</p>
            <p class="text-small my-0" >
                {{ $t('tasks.starts') }}: {{ fdate(task.starts) }} / 
                {{ $t('tasks.ends') }}: {{ fdate(task.ends) }}
            </p>


        </div>

    </div>
</template>

<script>
import UserInfo from "@/views/PostComponents/UserInfo.vue";
import moment from 'moment';
export default {
    props: ['task'],
    components: { UserInfo },
    methods: {
        fdate(dat) {
            return moment(dat).format('LL');
        }
    }
 }

</script>